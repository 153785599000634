html, body, * {
    margin: 0;
    padding: 0;
  }
  
  h1 {
    margin: 20px 0;
  }
  
  p {
    margin: 15px 0;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f0f0f0;
  }
  
  a{
    color: #fc763d;
    text-decoration: none;
  }
  
  a:hover{
    color: #c13a00
  }