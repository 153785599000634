.Header{
    background-color: #c50000;
    padding: 15px;
}

.Header .container{
    display: flex;
    margin: 0 auto;
    width: 1000px;
    align-items: center;
}

.Header .logotype{
    display: flex;
    align-items: center;
    color: #000000;
}

.Header .logotype h1{
    font-size: 1rem;
    margin:0 0 0 10px;
    color:#000000;
}

.Header .iconLogo{
    color: black;
    width: 30px;
    height: 30px;
}

.Header .container nav ul{
    display: flex;
    margin: 0;
}

.Header .container nav ul li{
    list-style-type: none;
    margin: 0 0 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Header .container nav ul li a{
    color: #000000;
    text-decoration: none;
    margin: 0 0 0 8px;
}

.Header .container nav ul li .icon-type{
    color: #000000;
    text-decoration: none;
}

.Header .container .Icon .Button {
    background: none;
    border: none;
    margin: 0 0 0 20px;
}

.Header .container .Icon .icon-filter{
    width: 20px;
    height: 20px;
    margin: 0 0 0 -1px;

}

.Header .container .test{
    position: relative;
    margin: 0 0 0 60px;
}

.Header .container .test .search{
    position: relative;
}

.Header .container .test .search .icon-search{
    position: absolute;
    top: 8px;
    left: 161px;
}

.Header .container nav ul li a:hover{
    color: #000000
}

.Header .container nav ul li a.active{
    color: #000000
}

.Header .container .search .form-control{
    color: #000000;
    border-radius: 19px;
    padding: 7px;
    width: 207px;
}

